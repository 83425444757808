import { html } from 'lit-html';
import { ShowCounterProps } from './show-counter.models';
import showCounterStyles from './show-counter.styles';

const showCounterTemplate = (props: ShowCounterProps) => {
  return html`
    <style>
      ${showCounterStyles}
    </style>

    <div class="db-show-counter">
      ${props.isExpired
        ? html`
            <label class="db-announce-label">New Dates Coming Soon</label>
          `
        : html`
            <div class="db-show-counter-values">
              <div class="db-show-counter-value">
                <h2>${props.days}</h2>
                <label>days</label>
              </div>

              <div class="db-show-counter-value">
                <h2>${props.hours}</h2>
                <label>hours</label>
              </div>

              <div class="db-show-counter-value">
                <h2>${props.minutes}</h2>
                <label>minutes</label>
              </div>

              <div class="db-show-counter-value">
                <h2>${props.seconds}</h2>
                <label>seconds</label>
              </div>
            </div>
          `}

      <hr />

      <div class="db-show-counter-subtitle">
        <h2 class="background text-center">
          ${props.isExpired
            ? 'Our next show is going to be awesome'
            : 'Till our next performance'}
        </h2>
      </div>
    </div>
  `;
};

export default showCounterTemplate;
