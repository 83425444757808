import { html, TemplateResult } from 'lit-html';
import { ButtonProps } from './button.models';
import buttonStyles from './button.styles';

const buttonTemplate = (props: ButtonProps) => {
  const content = html`
    ${props.label}
  `;

  let wrap: TemplateResult;
  if (props.as === 'a') {
    wrap = html`
      <a
        class="button-item ${props.theme} ${props.fill}"
        role="button"
        href="${props.href}"
      >
        ${content}
      </a>
    `;
  } else {
    wrap = html`
      <button class="button-item ${props.theme} ${props.fill}">
        ${content}
      </button>
    `;
  }

  return html`
    <style>
      ${buttonStyles}
    </style>

    ${wrap}
  `;
};

export default buttonTemplate;
