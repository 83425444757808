import { createTemplate, CustomElementData, define } from '../global';
import { ButtonProps } from './button.models';
import buttonTemplate from './button.template';

class DbButton extends HTMLElement implements CustomElementData<ButtonProps> {
  static get observedAttributes() {
    return ['label', 'as', 'theme', 'fill', 'href'];
  }

  public get label(): string {
    return this.getAttribute('label') as string;
  }

  public set label(v: string) {
    this.setAttribute('label', v);
  }

  public get href(): string {
    return this.getAttribute('href') as string;
  }

  public set href(v: string) {
    this.setAttribute('href', v);
  }

  public get as(): 'a' | 'button' {
    return this.getAttribute('as') as any;
  }

  public set as(v: 'a' | 'button') {
    this.setAttribute('as', v);
  }

  public get theme(): 'primary' | 'accent' | 'background' {
    return this.getAttribute('theme') as any;
  }

  public set theme(v: 'primary' | 'accent' | 'background') {
    this.setAttribute('theme', v);
  }

  public get fill(): 'solid' | 'none' {
    return this.getAttribute('fill') as any;
  }

  public set fill(v: 'solid' | 'none') {
    this.setAttribute('fill', v);
  }

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
  }

  public connectedCallback(): void {
    this.update();
  }

  public update(): void {
    if (this.shadowRoot) {
      createTemplate(this.shadowRoot, buttonTemplate(this));
    }
  }
}

define('db-button', DbButton).then(() => {
  console.log('BUTTON has been defined');
});
