import { html } from 'lit-html';
import scrollArrowStyles from './scroll-arrow.style';

const scrollArrowTemplate = html`
  <style type="text/css">
    ${scrollArrowStyles}
  </style>

  <div class="db-scroll-arrow-wrapper">
    <div class="db-scroll-arrow-label">Scroll For More Information</div>
    <div class="db-scroll-arrow-svg">
      <svg fill="none" viewBox="0 0 61 35">
        <path stroke="#13C79F" stroke-width="5" d="M59 2L30.5 31 2 2" />
      </svg>
    </div>
  </div>
`;

export default scrollArrowTemplate;
