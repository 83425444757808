import { createTemplate, CustomElementData, define } from '../global';
import { ShowCounterProps } from './show-counter.models';
import showCounterTemplate from './show-counter.template';

class DbShowCounter extends HTMLElement
  implements CustomElementData<ShowCounterProps> {
  public isExpired: boolean = false;

  public days: number = 0;

  public hours: number = 0;

  public minutes: number = 0;

  public seconds: number = 0;

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
  }

  public connectedCallback(): void {
    this.update();
    this.doCount(new Date('2024-08-15T09:00:00.000Z').getTime());
  }

  public update(): void {
    if (this.shadowRoot) {
      createTemplate(this.shadowRoot, showCounterTemplate(this));
    }
  }

  public doCount(countDownDate: number) {
    const now = new Date().getTime();
    const distance = countDownDate - now;
    this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
    this.hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    this.seconds = Math.floor((distance % (1000 * 60)) / 1000);

    if (Math.max(distance, 0) <= 0) {
      this.isExpired = true;
      this.update();
    } else {
      this.isExpired = false;

      setTimeout(() => {
        this.update();
        this.doCount(countDownDate);
      }, 1000);
    }
  }
}

define('db-show-counter', DbShowCounter).then(() => {
  console.log('SHOW-COUNTER has been defined');
});
