import { css } from '../global';

export const textInputStyles = css`
  :host {
    display: block;
    width: 100%;
    height: auto;
  }

  .wb-text-input {
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    margin-bottom: 2px;
  }

  .wb-text-input .underline {
    height: 2px;
    width: 100%;
    background: var(--light-grey);
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
  }

  .wb-text-input input,
  .wb-text-input textarea {
    display: block;
    width: 100%;
    height: 60px;
    line-height: 60px;
    border: none;
    outline: none;
    font-size: 32px;
    padding: 0;
    margin: 0;
    font-weight: 300;
    color: var(--light-grey);
    font-family: 'Nunito Sans', sans-serif;
  }

  .wb-text-input label {
    display: block;
    line-height: 60px;
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
    font-size: 32px;
    will-change: line-height, top, color;
    transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 120ms;
    color: var(--light-grey);
    font-weight: 300;
    font-family: 'Nunito Sans', sans-serif;
  }

  .wb-text-input input:focus ~ label,
  .wb-text-input input:not(:placeholder-shown) ~ label,
  .wb-text-input textarea:focus ~ label,
  .wb-text-input textarea:not(:placeholder-shown) ~ label {
    line-height: 20px;
    font-size: 14px;
    top: -14px;
    color: var(--primary);
  }

  .wb-text-input input:focus ~ .underline {
    background-color: var(--primary);
  }

  .wb-text-input input::placeholder,
  .wb-text-input textarea::placeholder {
    color: var(--background);
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  input,
  textarea {
    border-radius: 0 !important;
    appearance: none !important;
  }

  input::-ms-clear,
  input::-ms-reveal {
    display: none;
  }
`;
