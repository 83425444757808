import { createTemplate, CustomElementData, define } from '../global';
import scrollArrowTemplate from './scroll-arrow.template';

class DbScrollArrow extends HTMLElement implements CustomElementData<{}> {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
  }

  public connectedCallback(): void {
    this.update();
  }

  public update(): void {
    if (this.shadowRoot) {
      createTemplate(this.shadowRoot, scrollArrowTemplate);
    }
  }
}

define('db-scroll-arrow', DbScrollArrow).then(() => {
  console.log('SCROLL-ARROW has bee defined');
});
