import { render, TemplateResult } from 'lit-html';

export interface CustomElement {
  connectedCallback(): void;
  update(): void;
}

export type CustomElementData<T = {}> = CustomElement & T;

export function createTemplate(root: ShadowRoot, template: TemplateResult) {
  return render(template, root);
}

export function define(tagName: string, classHost: CustomElementConstructor) {
  if ('customElements' in window) {
    window.customElements.define(tagName, classHost);
    return window.customElements.whenDefined(tagName);
  } else {
    return Promise.reject('Custom Elements is not defined');
  }
}

// Compiles css string to output
export function css(strings: TemplateStringsArray, ...values: string[]) {
  let value: string = '';

  strings.forEach((string, i) => {
    value += string + (values[i] || '');
  });

  return value
    .trim()
    .replaceAll('\n', '')
    .replace(/(\s)+/g, ' ');
}
