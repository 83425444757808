import { css } from '../global';

const showCounterStyles = css`
  :host {
    display: block;
    color: var(--background);
  }

  .db-show-counter-values {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
  }

  .db-show-counter-values .db-show-counter-value {
    flex: 1;
    text-align: center;
  }

  @media screen and (max-width: 520px) {
    /* .db-show-counter-values .db-show-counter-value:nth-child(3),
    .db-show-counter-values .db-show-counter-value:nth-child(4) {
      display: none;
    } */

    .db-show-counter-values .db-show-counter-value {
      min-width: 50%;
      margin-bottom: 15px;
    }
  }

  .db-show-counter-values .db-show-counter-value h2,
  .db-show-counter-values .db-show-counter-value label {
    margin: 0;
    display: block;
  }

  .db-show-counter-values .db-show-counter-value h2 {
    margin-bottom: 5px;
    font-size: 45px;
    font-weight: 300;
  }

  @media screen and (max-width: 520px) {
    .db-show-counter-values .db-show-counter-value h2 {
      font-size: 30px !important;
    }
  }

  .db-show-counter-values .db-show-counter-value label {
    width: calc(100% - 20px);
    margin: auto;
    background-color: var(--accent);
    border-radius: 20px;
  }

  @media screen and (max-width: 520px) {
    .db-show-counter-values .db-show-counter-value label {
      font-size: 14px !important;
      width: calc(100% - 10px);
    }
  }

  hr {
    display: block;
    margin: 30px auto 20px auto;
    width: 90%;
    max-width: 340px;
    height: 1px;
    border: none;
    background-color: var(--background);
  }

  .db-show-counter-subtitle {
    text-align: center;
  }

  .db-show-counter-subtitle h2 {
    margin: 0;
    font-weight: 300;
    letter-spacing: 1px;
  }

  .db-announce-label {
    line-height: 1.2;
    font-size: 36px;
    display: block;
    text-align: center;
    background: var(--accent);
    padding: 10px 0;
    border-radius: 30px;
  }
`;

export default showCounterStyles;
