import { css } from '../global';

const scrollArrowStyles = css`
  :host {
    display: block;
    width: 100%;
  }

  .db-scroll-arrow-wrapper {
    display: block;
    width: 100%;
  }

  .db-scroll-arrow-wrapper .db-scroll-arrow-label {
    width: 100%;
    text-align: center;
    padding-bottom: 15px;
    color: var(--dark-grey);
  }

  .db-scroll-arrow-wrapper .db-scroll-arrow-svg {
    width: 100%;
    max-width: 60px;
    margin: auto;
  }
`;

export default scrollArrowStyles;
