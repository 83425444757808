import { html } from 'lit-html';
import { createTemplate, define } from '../global';
import { textInputStyles } from './text-input.styles';

const ALLOWED_INPUTS = ['backspace'];

class DbTextInput extends HTMLElement {
  static get observedAttributes() {
    return ['placeholder', 'id', 'type'];
  }

  public get placeholder(): string {
    return this.getAttribute('placeholder') as any;
  }

  public set placeholder(v: string) {
    this.setAttribute('placeholder', v);
  }

  public get id(): string {
    return this.getAttribute('id') as any;
  }

  public set id(v: string) {
    this.setAttribute('id', v);
  }

  public get type(): string {
    return this.getAttribute('type') ?? 'text';
  }

  public set type(v: string) {
    this.setAttribute('type', v);
  }

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
  }

  public connectedCallback(): void {
    // Do Init Work
    this.update();

    this.addEventListener('keydown', (event: KeyboardEvent) => {
      if (this.type === 'number') {
        const { key } = event;
        if (/[0-9]/g.test(key) || ALLOWED_INPUTS.includes(key.toLowerCase())) {
          return;
        }

        event.preventDefault();
      }
    });
  }

  public update() {
    if (this.shadowRoot) {
      createTemplate(
        this.shadowRoot,
        html`
          <style type="text/css">
            ${textInputStyles}
          </style>

          <div class="wb-text-input">
            ${this.type === 'textarea'
              ? html`
                  <textarea
                    required
                    id="${this.id}"
                    name="${this.id}"
                    placeholder="${this.placeholder}"
                  ></textarea>
                `
              : html`
                  <input
                    type="${this.type}"
                    placeholder="${this.placeholder}"
                    id="${this.id}"
                    name="${this.id}"
                    required
                  />
                `}

            <label for="${this.id}">${this.placeholder}</label>

            <div class="underline"></div>
          </div>
        `
      );
    }
  }

  public attributeChangedCallback(name: any, oldValue: any, newValue: any) {
    this.update();
  }
}

define('db-text-input', DbTextInput).then(() => {
  console.log('TEXT-INPUT has been defined');
});
