import { css } from '../global';

const buttonStyles = css`
  :host {
    display: contents;
    user-select: none;
  }

  .button-item {
    --button-color: var(--background);
    --button-background: var(--primary);
    --button-border-color: var(--primary);

    text-decoration: none;
    display: inline-block;
    line-height: 45px;
    padding: 0 30px;
    color: var(--button-color);
    background: var(--button-background);
    text-transform: uppercase;
    letter-spacing: 3%;
    cursor: pointer;
    border: solid 2px var(--button-border-color);
    border-radius: 10px;
    transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 200ms;
    font-size: 1.1em;
    font-weight: 300;
  }

  .button-item.accent {
    --button-background: var(--accent);
    --button-border-color: var(--accent);
  }

  .button-item.accent.none {
    --button-background: transparent;
    --button-color: var(--accent);
  }

  .button-item.accent.none:hover {
    --button-background: var(--accent);
    --button-color: var(--background);
  }

  .button-item.background {
    --button-color: var(--dark-grey);
    --button-background: var(--background);
    --button-border-color: var(--background);
  }

  .button-item.background.none {
    --button-color: var(--background);
    --button-background: transparent;
  }

  .button-item.background.none:hover {
    --button-background: var(--background);
    --button-color: var(--dark-grey);
  }

  .button-item.primary.none {
    --button-background: transparent;
    --button-color: var(--primary);
    --button-border-color: var(--primary);
  }

  .button-item.primary.none:hover {
    --button-background: var(--primary);
    --button-color: var(--background);
  }
`;

export default buttonStyles;
